// import React, {useRef, useEffect, useState} from 'react';
import React, { useEffect, useRef } from "react";
import useResizeObserver from "./useResizeObserver";
import { curveStep } from "d3";

function ImageBoundingBox({coordinates, emotions, imageUri, colors, video_url,softmax,input_channel}){

    // const img = imgRef.current;
    // const canvas = canvasRef.current;
    const imgRef = useRef();
    const canvasRef = useRef();
    let ctx = null;
    let coord_check = coordinates;
    // console.log(softmax);

    function sort_softmax(softmax_values){
        let temp_array = [];
        // console.log(softmax_values);
        for(let i  = 0; i < softmax_values.length; i++){
            let keyValues = [];
            for (let key in softmax_values[i]){
                keyValues.push([ key,softmax_values[i][key] ]);
            }
            keyValues.sort(function compare(kv1, kv2) {return kv2[1] - kv1[1]});
            temp_array.push(keyValues);
        }
        return temp_array;
    }

    

    useEffect(() => {

        const img = imgRef.current;
        const canvas = canvasRef.current;

        const hRatio = canvas.width / img.width    ;
        const vRatio = canvas.height / img.height  ;
        let height_offset = 0;
        let width_offset = 0;
        const ratio  = Math.min ( hRatio, vRatio );
        const sorted_softmax = sort_softmax(softmax);
        // console.log(sort_softmax(softmax));
        // console.log(sorted_softmax);
        ctx = canvas.getContext('2d');

        if(input_channel !== "webcam"){
            if(video_url === "Video 1"){
                width_offset = 0;
            } else{
                // width_offset = 50;
                // height_offset = -45;
            }
        }else{
            height_offset = 55;
            width_offset = 0;
        }

        img.onload = () =>{
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img,0,0,Math.min(img.width*ratio,canvas.width * ratio),Math.min(img.height*ratio,canvas.height * ratio));
            

            for (var i = 0; i < coordinates.length; i++){

                const coordinate = coordinates[i];
                const words_height = (15 * sorted_softmax[i].length) + 20;
                // console.log(words_height);
                // Only required to match colour with emotion, currently using default colour
                // const emotion = emotions[i];
                

                ctx.strokeStyle="#a9aeb6";
                ctx.lineWidth = 3;
                ctx.strokeRect(coordinate[0] * ratio - width_offset,coordinate[1]*ratio,coordinate[2]*ratio,coordinate[3] * ratio + height_offset);
                // ctx.fillStyle = colors[emotions[i]];
                ctx.fillStyle = "#a9aeb6";
                // ctx.fillRect((coordinate[0]*ratio) + (coordinate[2]* ratio), coordinate[1] * ratio , coordinate[2] * ratio, coordinate[3] *ratio);
                ctx.fillRect((coordinate[0]*ratio) + (coordinate[2]* ratio) - width_offset, coordinate[1] * ratio , 85, Math.max(coordinate[3] * ratio + height_offset, words_height + 5 ));
                ctx.fillStyle = "#000000";
                ctx.font = "10px Arial";
                let position_counter = 20;
                let align = 0;
                if ((coordinate[3]*ratio) > words_height){
                    align = ((coordinate[3] * ratio + height_offset) - words_height) / 2;
                }
                for(let y = 0; y < sorted_softmax[i].length; y ++) {
                    let key = sorted_softmax[i][y][0];
                    let value = sorted_softmax[i][y][1];
                    let message = key + " : " + value.toFixed(3);
                    ctx.fillText(message, (coordinate[0]*ratio) + (coordinate[2]* ratio) + 5 - width_offset , (coordinate[1]*ratio) + align + position_counter);
                    position_counter = position_counter + 15;
                }

                

                // ctx.fillStyle = colors[emotions[i]];
                // ctx.fillRect(coordinate[0]*ratio, coordinate[1] * ratio - 30 , coordinate[2]* ratio, 30);
                // ctx.fillStyle = "#000000";
                // ctx.font = "30px Arial";
                // ctx.fillText(emotion, coordinate[0]*ratio + (coordinate[2]*ratio / 12), coordinate[1]*ratio - (20/4));
            }
        };
        
        // coord_check = coordinates;
    }, [coordinates,softmax, emotions, imageUri, colors, video_url]);

    return (
        <React.Fragment>
                <canvas className="Image-canvas" ref={canvasRef} width="720" height="450">
                    <img className="Boundingbox-image" ref={imgRef} src={imageUri}></img>
                </canvas>
        </React.Fragment>
    );
}

export default ImageBoundingBox;