// import React, {useRef, useEffect, useState} from 'react';
import React, { useEffect, useRef, useState } from "react";
import {
  select,
  selectAll,
  scaleBand,
  axisBottom,
  line,
  scaleLinear,
  axisLeft,
  curveMonotoneX,
  mouse
} from "d3";
import useResizeObserver from "./useResizeObserver";

function MultiLineChart({data, keys, colors, mouseCallBack}){

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    // const [mouseLocation, setMouseLocation] = useState(0.0);

    useEffect(() => {

        // console.log(data);

        const svg = select(svgRef.current);
        let {width, height} = 
            dimensions || wrapperRef.current.getBoundingClientRect();
        height = height + 100;
        const extent = [0, 1];
        // console.log(width)
            
        const xScale = scaleBand()
            .domain(data.map(d => d.time))
            .range([0, width]);
        
        
        const fixed_xScale = scaleBand()
            .domain(Array.from( {length: Math.round(data.slice(-1)[0].time)}, (x,i) => i + 1 ))
            .range([0, width]);

        const yScale = scaleLinear()
            .domain(extent)
            .range([height, 0])

        const xAxis = axisBottom(fixed_xScale);
        const yAxis = axisLeft(yScale);

        const lineGenerator = line()
                                .curve(curveMonotoneX)
                                .x((d,i) => xScale(data.map(d => d.time)[i]))
                                .y(d => yScale(d));

        svg
            .select(".path-lines")
            .selectAll("path")
            .data(keys.filter(function(emotion_class){return emotion_class != "SelectAll"}))
            .join("path")
            .attr("d", key => {
                return lineGenerator(data.map(d => d[key]))
            })
            .attr("stroke",key => colors[key])
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("class","line");

        svg
            .select(".x-axis")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis);

        svg
            .select(".y-axis")
            .call(yAxis.ticks(5));

        const mouseG = svg.append("g")
            .attr("class", "mouse-over-effects");

        mouseG.append("path")
            .attr("class","mouse-line")
            .style("stroke", "black")
            .style("stroke-width", "1px")
            .style("opacity", "0");

        const lines = document.getElementsByClassName('line');
            
        var mousePerLine = mouseG.selectAll('.mouse-per-line')
            .data(keys.filter(function(emotion_class){return emotion_class != "SelectAll"}))
            .enter()
            .append("g")
            .attr("class", "mouse-per-line");

        mousePerLine.append("circle")
            .attr("r", 7)
            .style("stroke", key => colors[key])
            .style("fill", "none")
            .style("stroke-width", "1px")
            .style("opacity", "0");

        mousePerLine.append("text")
            .attr("transform", "translate(10,3)");

        let pos;

        mouseG.append('svg:rect') // append a rect to catch mouse movements on canvas
            .attr('width', width) // can't catch mouse events on a g element
            .attr('height', height)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mouseout', function() { // on mouse out hide line, circles and text
              select(".mouse-line")
                .style("opacity", "0");
              selectAll(".mouse-per-line circle")
                .style("opacity", "0");
              selectAll(".mouse-per-line text")
                .style("opacity", "0");
            })
            .on('mouseover', function() { // on mouse in show line, circles and text
                select(".mouse-line")
                    .style("opacity", "1");
                selectAll(".mouse-per-line circle")
                    .style("opacity", "1");
                selectAll(".mouse-per-line text")
                    .style("opacity", "1");
            })
            .on('mousemove', function() { // mouse moving over canvas
                let cur_mouse = mouse(this);
                select(".mouse-line")
                    .attr("d", function() {
                        var d = "M" + cur_mouse[0] + "," + height;
                        d += " " + cur_mouse[0] + "," + 0;
                        return d;
                    });
                selectAll(".mouse-per-line")
                    .attr("transform", function(d, i) {
                        if(lines[i]  !== undefined) {
                            let beginning = 0;
                            let end = lines[i].getTotalLength();
                            let target = null;
            
                            while (true){
                                let target = Math.floor((beginning + end) / 2);
                                pos = lines[i].getPointAtLength(target);

                                if ((target === end || target === beginning) && pos.x !== cur_mouse[0]) {
                                    break;
                                }

                                if (pos.x > cur_mouse[0]) end = target;
                                else if (pos.x < cur_mouse[0]) beginning = target;
                                else break; //position found
                            }
                        
                            select(this).select('text')
                                .text(yScale.invert(pos.y).toFixed(2));

                            return "translate(" + cur_mouse[0] + "," + pos.y +")";
                        }
                    });
            })
            .on("click", function(){
                let cur_mouse = mouse(this);
                const eachBand = xScale.step();
                const index = Math.round((cur_mouse[0] / eachBand));
                const cur_loc = xScale.domain()[index];
                // setMouseLocation(cur_loc);
                // console.log(mouseCallBack);
                mouseCallBack(cur_loc);
            });
        

    }, [data, dimensions, keys, colors]);

    return (
        <React.Fragment>
            <div ref={wrapperRef} style={{ marginBottom: "2rem" }}>
            <svg ref={svgRef}>
                <g className="path-lines"/>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
            </div>
        </React.Fragment>
    );
}

export default MultiLineChart;