import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import StackBarChart from "./StackBarChart";
import RacingBarChart from "./RacingBarChart";
import ImageBoundingBox from "./ImageBoundingBox";
import PieChart from "./PieChart";
import MultiLineChart from './MultiLineChart';
// import RadarChart from './RadarChart';
import Radar from 'react-d3-radar';
import 'react-svg-radar-chart/build/css/index.css';
import Webcam from "react-webcam";

// Video Components
import ReactPlayer from '../node_modules/react-player';
import Video1 from "./asset/videos/v1_f1.mp4";
import Video2 from "./asset/videos/v2_f1.mp4";
import Video3 from "./asset/videos/v3_f1.mp4";
import Video4 from "./asset/videos/v4_f1.mp4";
import Video5 from "./asset/videos/v5_f1.mp4";
import colorWheel from "./asset/images/emotion_wheel2_colour.png";
import paLogo from "./asset/images/logo.png";
import CaptureVideoFrame from '../node_modules/capture-video-frame';
import { set } from 'd3';


const stack_data_pre = [{time:0,"Angry":0,"Disgust":0,"Fear":0,"Happy":0,"Sad":0,"Surprise":0,"Neutral":0}];
const bar_data_pre = [{emotion:"Angry",occurence:0},{emotion : "Disgust",occurence:0},{emotion : "Fear", occurence:0},{emotion:"Happy",occurence:0},{emotion:"Sad",occurence:0},{emotion:"Surprise",occurence:0},{emotion:"Neutral",occurence:0}];
const allKeys = ["Angry","Disgust","Fear","Happy","Sad","Surprise","Neutral"];
const colors = {"Angry": "#ff5050","Disgust": "#cc66ff","Fear": "#333300","Happy": "#b3b300","Sad": "#0067cd","Surprise": "#008080","Neutral": "#a9aeb6"};
const pie_data_pre = {Angry:0, Disgust:0, Fear:0, Happy:0, Sad:0, Surprise:0, Neutral:0};

const getRandomIndex = array => {
  return Math.floor(array.length * Math.random());
};

const videoTitles = ["Video 1", "Video 2", "Video 3", "Video 4", "Video 5"];

function blobToDataURL(blob, callback) {
  var a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
}

function App() {

  const [userId, setUserId] = useState(0);
  // const [keys, setKeys] = useState(allKeys.filter(function(emotion_class){return emotion_class != "Neutral"}));
  const [keys, setKeys] = useState(allKeys);
  const [bar_data, setBarData] = useState(bar_data_pre);
  const [stack_data, setStackData] = useState(stack_data_pre);
  const [pie_data, setPieData] = useState(pie_data_pre);
  const [video_url, setVideoFileurl] = useState(videoTitles[0]);
  const [radar_data, setRadarData] = useState([]);
  const [coordinates, setCoord] = useState([]);
  const [emotions, setEmotions] = useState([]);
  const [input_channel,setChannel] = useState("video");
  const [input_button_text,setInputText] = useState("Change to Web Cam");
  const [webcam_threshold, setThreshold] = useState(30000);
  const [webcam_button, setWebcamButton] = useState("Stop webcam");

  const [all_bar_data, setAllBarData] = useState({});
  const [all_stack_data, setAllStackData] = useState({});
  const [all_pie_data, setAllPieData] = useState({});
  const [all_radar_data, setAllRadarData] = useState({});
  const [all_coordinates, setAllCoord] = useState({});
  const [all_emotions, setAllEmotions] = useState({});
  const [times, setTimes] = useState({});
  const [all_data,setAllData] = useState(false);
  const [video_counter, setVideoCounter] = useState(0);

  const [overlay_switch,setOverlay] = useState(true);
  const [overlay_text,setOverlayText] = useState("Off Overlay");
  // const [image_data, setImageData] = useState(0);
  const [selectall_text,setSelectallText] = useState("Select All");
  const [selectall_switch, setSelectall] = useState(true);
  const [request_check,setRequestCheck] = useState(true);

  const [selected_softmax, setSelSoftmax] = useState([]);
  const [selected_coordinates, setSelCoord] = useState([]);
  const [selected_emotions, setSelEmotions] = useState([]);
  const [imageUri, setImageUri] = useState("");
  const [mouseLocation, setMouseLocation] = useState(0.0);
  const [update_mouse, setUpdateMouse] = useState(false);

  const videoRef = useRef();
  const [video_progress, setVideoProgress] = useState(0);
  const video_objs = { "Video 1":Video1, "Video 2":Video2, "Video 3":Video3, "Video 4":Video4, "Video 5":Video5 };

  const imgRef = useRef();
  const canvasRef = useRef();
  let ctx = null;
  const [check_progress,setCheckProgress] = useState(0);

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
  }
  
  useEffect(() => {

    

    const player = videoRef.current;
    const img = imgRef.current;
    let imageFile;
    let frame_uri;
    let webcam_offset = 0;

    const interval = setInterval(() => {
      if (input_channel === "webcam"){
        if(video_progress < webcam_threshold){
          setVideoProgress(Math.round(video_progress + 400));
        } else{
          setWebcamButton("Restart webcam");
        }
      } else {
        if(all_data && video_counter < times.length){
          
          setVideoProgress(times[video_counter]);
          setVideoCounter(video_counter + 1);

          if(all_stack_data[video_progress] != undefined){
            setStackData(all_stack_data[video_progress]);
            setBarData(all_bar_data[video_progress]);
            setCoord(all_coordinates[video_progress]);
            setEmotions(all_emotions[video_progress]);
            setPieData(all_pie_data[video_progress]);
            setRadarData(prepare_radar_data(all_radar_data[video_progress][0]));
          }

        } else if(video_counter === 0){
          setVideoProgress(video_progress + 1);
        }
      } 
    }, 400);

    if (check_progress != video_progress || request_check){

      setCheckProgress(video_progress);
    
      if(input_channel === "video"){

        const frame = CaptureVideoFrame(player.getInternalPlayer());
        
        // Use this blob to pass into API
        frame_uri = frame.dataUri;
        imageFile = frame.blob;
        
      } else if(input_channel === "webcam"){

        if (player.getScreenshot() != null){
          // const frame = CaptureVideoFrame(player.getInternalPlayer());
          frame_uri = player.getScreenshot();
          imageFile = dataURItoBlob(frame_uri);
        }

        webcam_offset = 65;

      }
      
      
      if( typeof imageFile === 'object' && imageFile["size"] > 0 && (request_check || userId > 0) && input_channel === "webcam"){
          
          setRequestCheck(false);

          const dataForm = new FormData();
          dataForm.append('data',imageFile, "image.jpeg");
          dataForm.append('source','react_app');
          dataForm.append('time',video_progress);
          dataForm.append('video',video_url);
          dataForm.append('channel',input_channel);
          dataForm.append('imageUri', frame_uri);
          dataForm.append('userId', userId);
          const requestFields = {
              method: 'POST',
              body: dataForm
          };
          
          fetch('https://faceinmotion.preferred.ai/api/emotion', requestFields)
              .then(response => response.json())
              .then(data => {
                // console.log(data);
                setStackData(data.stack_data);
                setBarData(data.bar_data);
                setCoord(data.coordinates);
                setEmotions(data.context_emotions_ff);
                setPieData(data.pie_data);
                setRadarData(prepare_radar_data(data.radar_data));
                if(userId === 0){
                  setUserId(data.userId);
                }
              });
              
      } else if( typeof imageFile === 'object' && imageFile["size"] > 0 && (request_check)){

          setRequestCheck(false);

          const dataForm = new FormData();
          dataForm.append('data',imageFile, "image.jpeg")
          dataForm.append('source','react_app')
          dataForm.append('time',video_progress)
          dataForm.append('video',video_url)
          dataForm.append('channel',input_channel);
          dataForm.append('imageUri', frame_uri)
          dataForm.append('userId', userId)
          const requestFields = {
              method: 'POST',
              body: dataForm
          };
          
          fetch('https://faceinmotion.preferred.ai/api/emotion', requestFields)
              .then(response => response.json())
              .then(data => {
                // console.log(data);
                setAllStackData(data.stack_data);
                setAllBarData(data.bar_data);
                setAllCoord(data.coordinates);
                setAllEmotions(data.context_emotions_ff);
                setAllPieData(data.pie_data);
                setAllRadarData(data.radar_data);
                setAllData(true);
                setTimes(data.times);
                if(userId === 0){
                  setUserId(data.userId);
                }
                // setVideoProgress(times[0])
              });
      }

      const canvas = canvasRef.current;
      img.src = frame_uri;

      var hRatio = canvas.width / img.width    ;
      var vRatio = canvas.height / img.height  ;
      var ratio  = Math.min ( hRatio, vRatio );

      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if(overlay_switch){

        for (var i = 0; i < coordinates.length; i++){

            const coordinate = coordinates[i];
            const emotion = emotions[i];

            ctx.strokeStyle = colors[emotion];
            ctx.lineWidth = 3;
            ctx.strokeRect(coordinate[0] * ratio + webcam_offset,coordinate[1] * ratio, coordinate[2]* ratio, coordinate[3] * ratio + (webcam_offset / 2));
            ctx.fillStyle = colors[emotions[i]];
            ctx.fillRect(coordinate[0] * ratio + webcam_offset, coordinate[1] * ratio - 25, coordinate[2] * ratio , 25);
            ctx.fillStyle = "#000000";
            ctx.font = "15px Arial";
            ctx.fillText(emotion, coordinate[0]*ratio + (coordinate[2]*ratio / 12) + webcam_offset, coordinate[1]*ratio - (30/4));
        }
      }
    }

    if(update_mouse){

      const dataForm = new FormData();
      dataForm.append('keyword',video_url + " " + mouseLocation);
      dataForm.append('userId', userId);
      const requestFields = {
          method: 'POST',
          body: dataForm
      };

      // console.log(video_url + " " + mouseLocation);

      fetch('https://faceinmotion.preferred.ai/api/analyse', requestFields)
      .then(function(response){
        return response.json();
      })
      .then(data => {
        if(data !== ""){
          // console.log(data);
          setImageUri(data[0]);
          setSelCoord(data[1]);
          setSelEmotions(data[2]);
          setSelSoftmax(prepare_radar_data(data[3]));
        }
      });

      setUpdateMouse(false);
    }

    if(keys.length === 7){
      setSelectallText("Deselect All")
      setSelectall(false)
    }else if (keys.length === 0){
      setSelectallText("Select All")
      setSelectall(true)
    }

    return () => clearInterval(interval);

  },[video_progress, video_counter, mouseLocation, keys, input_channel,request_check, webcam_threshold, userId]);

  function callbackFunction (childData) {
    setMouseLocation(childData);
    setUpdateMouse(true);
  };

  function prepare_variable_data(){
    let temp_array = [];
    for(let i = 0; i < keys.length; i++) {
      const temp_dict = {key:keys[i],label:keys[i]}
      temp_array.push(temp_dict);
    }
    if(temp_array.length === 0){
      temp_array = [{key:"None", label:"None"}];
    }
    return temp_array;
  };

  function prepare_radar_data(softmax_values){
    let temp_array = [];
    for(let i = 0; i < softmax_values.length; i++){ 
      const temp_dict = {};
      if(softmax_values.length > 0){
        for (let y = 0; y < keys.length; y++) {
          temp_dict[keys[y]] = softmax_values[i][y];
        }
      }
      temp_array.push(temp_dict);
    }
    return temp_array;
    
  };

  if (input_channel === "video"){
    return (
      <React.Fragment>
        <div class="Header-container">
          <div class="siteLogo">
            {/* <a href="https://preferred.ai">Preferred.AI</a> */}
            <img src={paLogo} width="500" height="90"></img>
          </div>
          <p class="appLogo">
            <span>FaceInMotion</span><br/><i>FACE-based INtelligent Emotion Recognition</i>
          </p>
        </div>

        <section className="Body-section">
          <div className="Top-level" >
            <img className="hidden" ref={imgRef}></img>
            <div className="Video-container">
                <div className="select_fields">
                  <button
                    className="Input-button"
                    onClick={e=>{
                      setChannel("webcam");
                      setRequestCheck(true);
                      setVideoCounter(0);
                      setInputText("Change to Video");
                      setVideoFileurl("Webcam");
                      setVideoProgress(0);
                      const dataForm = new FormData();
                      dataForm.append('userId',userId);
                      fetch('https://faceinmotion.preferred.ai/api/emotion', {method : 'PUT', body: dataForm})
                        // .then(response => console.log(response));
                    }}
                  >
                    {input_button_text}
                  </button>
                  <button
                    className="Overlay-button"
                    onClick={e=>{
                      if(overlay_switch){
                        setOverlay(false)
                        setOverlayText("On Overlay")
                      } else{
                        setOverlay(true)
                        setOverlayText("Off Overlay")
                      }
                    }}
                  >
                    {overlay_text}
                  </button>
                  <select 
                    className="Video-dropdown" 
                    onChange={e => {
                      setImageUri("");
                      setSelCoord([]);
                      setSelEmotions([]);
                      setRequestCheck(true);
                      setVideoCounter(0);
                      setAllData(false);
                      setMouseLocation(0.0);
                      setVideoFileurl( e.target.value );
                      const dataForm = new FormData();
                      dataForm.append('userId',userId);
                      fetch('https://faceinmotion.preferred.ai/api/emotion', {method : 'PUT', body: dataForm})
                        // .then(response => console.log(response));
                    }}
                    >
                    
                    {videoTitles.map(key => ( 
                      <option key={key} value={key}> {key} </option>
                    ))}
                  </select>
                </div>

                <ReactPlayer 
                    ref={videoRef}
                    url={video_objs[video_url]}
                    className="Video-player"
                    width="675px"
                    height="450px" 
                    playing={true} 
                    controls={true} 
                    muted={true}
                    progressInterval={250} 
                    onProgress={e => {
                        // setVideoProgress(Math.round(e.playedSeconds * 1000));
                    }}
                />
                <canvas className="Video-canvas" ref={canvasRef} width="675" height="525"></canvas>
                <div className="clear"></div>
            </div>
            
            <div className="Display-image">
                <p>Analysis of Selected Frame</p>
                <ImageBoundingBox coordinates={selected_coordinates} emotions={selected_emotions} imageUri={imageUri} colors={colors} video_url={video_url} softmax={selected_softmax} input_channel={input_channel}/>
            </div>

          </div>
          

          <div className="Bottom-level">
            <div className="Middle-level">
              <div className="Filter-fields">
                {allKeys.map(key => (
                  <div key={key} className="field">
                    <input
                      id={key}
                      type="checkbox"
                      checked={keys.includes(key)}
                      onChange={e => {
                        if(key !== "SelectAll"){
                          if (e.target.checked) {
                            setKeys(Array.from(new Set([...keys, key])));
                          } else {
                            setKeys(keys.filter(_key => _key !== key && _key!== "SelectAll"));
                          }
                        }
                      }}
                    />
                    <div htmlFor={key} className={key}>
                      <label className="Checkbox-button" htmlFor={key} style={{ color: "#FFFFFF"}}>
                        <h3>{key}</h3>
                      </label>
                    </div>
                  </div>
                ))}
                <button
                  className="Selectall-button"
                  onClick={e=>{
                    if(selectall_switch){
                      setKeys(Array.from(new Set([...allKeys])));
                      setSelectallText("Deselect All")
                      setSelectall(false)
                    } else{
                      setKeys(keys.filter(_key => false));
                      setSelectallText("Select All")
                      setSelectall(true)
                    }
                  }}
                >
                  {selectall_text}
                </button>
              </div>
              
            </div>

            <div className="Racing-Chart">
              <RacingBarChart data={bar_data} keys={keys} colors={colors}/>
            </div>

            <div className="Multiline-Chart">
              <MultiLineChart  data={stack_data} keys={keys} colors={colors} mouseCallBack = {callbackFunction}/>
            </div>

            <div className="Pie-Chart">
              <PieChart data={pie_data} keys={keys} colors={colors} />
            </div>

            <div className="Radar-Chart">
              <Radar
                width={400}
                height={230}
                padding={10}
                domainMax={1.0}
                highlighted={null}
                style={'ringColor=black'}
                data={{
                  variables: prepare_variable_data(),
                  sets:[
                    {
                      key:'overall',
                      label:'Overall',
                      values: radar_data[0]
                    },{
                      key:'selected 1',
                      label:'Selected 1',
                      values: selected_softmax[0]
                    },
                    // {
                    //   key:'selected 2',
                    //   label:'Selected 2',
                    //   values: selected_softmax[1]
                    // },
                    // {
                    //   key:'selected 3',
                    //   label:'Selected 3',
                    //   values: selected_softmax[2]
                    // }
                  ]
                }}
              />
            </div>
            
          </div>
          <div className="Color-Wheel">
            <img src={colorWheel}></img>
            <h3>Source: <a href="https://www.glenntrigg.net/emotions-wheel/">Glenn Trigg</a></h3>
          </div>
          
        </section>
        {/* <StackBarChart data={stack_data} keys={keys} colors={colors} /> */}
      </React.Fragment>
    );
  } else if (input_channel === "webcam"){
    return (
      <React.Fragment>
        <div class="Header-container">
          <div class="siteLogo">
            {/* <a href="https://preferred.ai">Preferred.AI</a> */}
            <img src={paLogo} width="500" height="90"></img>
          </div>
          <p class="appLogo">
            <span>FaceInMotion</span><br/><i>FACE-based INtelligent Emotion Recognition</i>
          </p>
        </div>

        <section>
          <div className="Top-level" >
            <img className="hidden" ref={imgRef}></img>
            <div className="Video-container">
                <div className="select_fields">
                  <button
                    className="Input-button"
                    onClick={e=>{
                      setChannel("video");
                      setInputText("Change to Web Cam");
                      setRequestCheck(true);
                      setVideoFileurl(videoTitles[0]);
                      setVideoProgress(0);
                      const dataForm = new FormData();
                      dataForm.append('userId',userId);
                      fetch('https://faceinmotion.preferred.ai/api/emotion', {method : 'PUT', body: dataForm})
                        // .then(response => console.log(response));
                    }}
                  >
                    {input_button_text}
                  </button>
                  <button
                    className="Overlay-button"
                    onClick={e=>{
                      if(overlay_switch){
                        setOverlay(false)
                        setOverlayText("On Overlay")
                      } else{
                        setOverlay(true)
                        setOverlayText("Off Overlay")
                      }
                    }}
                  >
                    {overlay_text}
                  </button>
                  <button
                    className="Threshold-button"
                    onClick={e=>{
                      // setThreshold(webcam_threshold + 15000);
                      if(webcam_button === "Restart webcam"){
                        setThreshold(30000);
                        setVideoProgress(0);
                        setWebcamButton("Stop webcam");
                        const dataForm = new FormData();
                        dataForm.append('userId',userId);
                        fetch('https://faceinmotion.preferred.ai/api/emotion', {method : 'PUT', body: dataForm})
                          // .then(response => console.log(response));
                      } else if (webcam_button === "Stop webcam"){
                        setThreshold(video_progress);
                        setWebcamButton("Restart webcam");
                      }
                    }}
                  >
                    {webcam_button}
                  </button>
                </div>
                <Webcam
                  audio={false}
                  height={375}
                  ref={videoRef}
                  screenshotFormat="image/jpeg"
                  width={675.5}
                />
                <canvas className="Video-canvas" ref={canvasRef} width="675" height="375.5"></canvas>
                <div className="clear"></div>
            </div>
            
            <div className="Display-image">
                <p>Analysis of Selected Frame</p>
                <ImageBoundingBox coordinates={selected_coordinates} emotions={selected_emotions} imageUri={imageUri} colors={colors} video_url={video_url} softmax={selected_softmax} input_channel={input_channel}/>
            </div>

          </div>
          

          <div className="Bottom-level">
            <div className="Middle-level">
              <div className="Filter-fields">
                {allKeys.map(key => (
                  <div key={key} className="field">
                    <input
                      id={key}
                      type="checkbox"
                      checked={keys.includes(key)}
                      onChange={e => {
                        if(key !== "SelectAll"){
                          if (e.target.checked) {
                            setKeys(Array.from(new Set([...keys, key])));
                          } else {
                            setKeys(keys.filter(_key => _key !== key && _key!== "SelectAll"));
                          }
                        }
                      }}
                    />
                    <div htmlFor={key} className={key}>
                      <label className="Checkbox-button" htmlFor={key} style={{ color: "#FFFFFF"}}>
                        <h3>{key}</h3>
                      </label>
                    </div>
                  </div>
                ))}
                <button
                  className="Selectall-button"
                  onClick={e=>{
                    if(selectall_switch){
                      setKeys(Array.from(new Set([...allKeys])));
                      setSelectallText("Deselect All")
                      setSelectall(false)
                    } else{
                      setKeys(keys.filter(_key => false));
                      setSelectallText("Select All")
                      setSelectall(true)
                    }
                  }}
                >
                  {selectall_text}
                </button>
              </div>
              
            </div>

            <div className="Racing-Chart">
              <RacingBarChart data={bar_data} keys={keys} colors={colors}/>
            </div>

            <div className="Multiline-Chart">
              <MultiLineChart  data={stack_data} keys={keys} colors={colors} mouseCallBack = {callbackFunction}/>
            </div>

            <div className="Pie-Chart">
              <PieChart data={pie_data} keys={keys} colors={colors} />
            </div>

            <div className="Radar-Chart">
              <Radar
                width={400}
                height={200}
                padding={10}
                domainMax={1.0}
                highlighted={null}
                style={'ringColor=black'}
                data={{
                  variables: prepare_variable_data(),
                  sets:[
                    {
                      key:'overall',
                      label:'Overall',
                      values: radar_data[0]
                    },{
                      key:'selected 1',
                      label:'Selected 1',
                      values: selected_softmax[0]
                    },
                    // {
                    //   key:'selected 2',
                    //   label:'Selected 2',
                    //   values: selected_softmax[1]
                    // },
                    // {
                    //   key:'selected 3',
                    //   label:'Selected 3',
                    //   values: selected_softmax[2]
                    // }
                  ]
                }}
              />
            </div>
            
          </div>
          <div className="Color-Wheel">
            <img src={colorWheel}></img>
            <h2>Source: <a href="https://www.glenntrigg.net/emotions-wheel/">Glenn Trigg</a></h2>
          </div>
          
        </section>
        {/* <StackBarChart data={stack_data} keys={keys} colors={colors} /> */}
      </React.Fragment>
    );
  }
}

export default App;
