import React, { useRef, useEffect } from "react";
import { select, scaleBand, scaleLinear, max } from "d3";
import useResizeObserver from "./useResizeObserver";

function RacingBarChart({data, keys, colors}){
    
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    useEffect(() => {

        keys = keys.filter(function(emotion_class){return emotion_class != "SelectAll"});
        const svg = select(svgRef.current);
        const {width, height} = 
            dimensions || wrapperRef.current.getBoundingClientRect();
        // let filtered_data;
        // if (keys.includes("SelectAll")){

        const filtered_data = data.filter(function(key){
            let checker = false;
            if (keys.includes(key.emotion)){
                checker = true;
            }
            return checker;
        });

        filtered_data.sort((a,b) => b.occurence - a.occurence);

        const yScale = scaleBand()
            .paddingInner(0.1)
            .domain(filtered_data.map((occurence, index) => index))
            .range([0, height+100]);

        const xScale = scaleLinear()
            .domain([0, max(filtered_data, (occurence,index) => occurence.occurence)])
            .range([0, width]);

        svg
            .selectAll(".bar")
            .data(filtered_data, (occurence, index) => occurence.emotion)
            .join(enter =>
              enter.append("rect").attr("y", (occurence, index) => yScale(index))
            )
            .attr("fill", occurence => {
                return colors[occurence.emotion]
            })
            .attr("class", "bar")
            .attr("x", 0)
            .attr("height", yScale.bandwidth())
            .transition()
            .attr("width", occurence => xScale(occurence.occurence))
            .attr("y", (occurence, index) => yScale(index));
      
        // draw the labels
        svg
            .selectAll(".label")
            .data(filtered_data, (occurence, index) => occurence.emotion)
            .join(enter =>
                enter
                .append("text")
                .attr("y",(occurence, index) => yScale(index) + yScale.bandwidth() / 2 + 7)
                .attr("font-size","18px")
            )
            .text(occurence => `${occurence.emotion} (${occurence.occurence} Frames)`)
            .attr("class", "label")
            .attr("x", 10)
            .transition()
            .attr("y", (occurence, index) => yScale(index) + yScale.bandwidth() / 2 + 7);

        }, [data, dimensions, keys]);
    
    return (
        <React.Fragment>
          <div ref={wrapperRef} style={{ marginBottom: "2rem" }}>
            <svg ref={svgRef}></svg>
          </div>
        </React.Fragment>
      );

}

export default RacingBarChart;