import React, { useRef, useEffect } from "react";
import { select, arc, pie, entries } from "d3";
import useResizeObserver from "./useResizeObserver";

function PieChart({data, keys, colors}){
    
    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);

    function filter_data(unfiltered){
        let temp_dict = {};
        for(let i = 0; i < keys.length; i ++){
            temp_dict[keys[i]] = data[keys[i]];
        }
        return temp_dict;
    }

    useEffect(() => {

        const filtered_data = filter_data(data);
        let svg = select(svgRef.current);
        const {width, height} = 
            dimensions || wrapperRef.current.getBoundingClientRect();
        const margin = 40;
        const radius = Math.min(width,height) / 2 - margin;

        const pie_object = pie()
            .value(function(d) {
                return d.value;
            });
        
        const pieData = pie_object(entries(filtered_data));

        var arcGenerator = arc()
            .innerRadius(0)
            .outerRadius(radius);

        svg
            .selectAll("g")
            .remove();

        svg = svg
            .append("g")
                .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        svg
            .selectAll('mySilces')
            .data(pieData)
            .enter()
            .append('path')
                .attr('d', arcGenerator)
                .attr('fill', function(d){
                    return colors[d.data.key];
                })
                .attr("stroke", "black")
                .style("stroke-width", "2px")
                .style("opacity", 0.7)
                .transition();
            
        svg
            .selectAll('mySlices')
            .data(pieData)
            .enter()
            .append('text')
                .text(function(d){return Math.round(d.data.value * 100) + "%"})
                .attr("transform", function(d) { return "translate(" + arcGenerator.centroid(d) + ")";  })
                .style("text-anchor", "middle")
                .style("font-size", 13)
                .transition();

    }, [data, dimensions, keys]);
    
    return (
        <React.Fragment>
          <div ref={wrapperRef} style={{ height:"450px", marginBottom: "2rem" }}>
            <svg ref={svgRef}></svg>
          </div>
        </React.Fragment>
      );

}

export default PieChart;